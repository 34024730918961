<template>
  <div class="objectifs">
    <div class="title">Les objectifs des filiales</div>
    <hr />
    <div class="objectifs-container">
      <div
        v-if="
          (getReportingProcessing || !getReportingObjectifs.loaded) &&
            !getReportingError
        "
        class="stat-container three-dots-loading"
      >
        Chargement en cours
      </div>
      <div v-if="getReportingError" class="error">
        <ul>
          <li v-for="(error, index) in getReportingError" :key="index">
            {{ error }}
          </li>
        </ul>
      </div>
      <div class="grid-container">
        <table class="grid" v-if="displayCondition">
          <colgroup>
            <col width="100px" />
            <col width="80px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="35px" />
            <col width="40px" />
            <col width="35px" />
            <col width="40px" />
            <col width="35px" />
            <col width="40px" />
          </colgroup>
          <tr class="tr-1">
            <th colspan="2" rowspan="3" class="grey-light s-14 t-up sticky">
              Filiale / Dépôts
            </th>
            <th colspan="6" class="grey-light s-14">Total / dépôt</th>
            <th colspan="6" class="grey-light s-12 t-up">Lundi</th>
            <th colspan="6" class="grey-light s-12 t-up">Mardi</th>
            <th colspan="6" class="grey-light s-12 t-up">Mercredi</th>
            <th colspan="6" class="grey-light s-12 t-up">Jeudi</th>
            <th colspan="6" class="grey-light s-12 t-up">Vendredi</th>
            <th colspan="6" class="grey-light s-12 t-up">Samedi</th>
            <th colspan="6" class="grey-light s-12 t-up">Dimanche</th>
          </tr>
          <tr class="tr-2">
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
          </tr>
          <tr class="tr-2">
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
          </tr>
          <tr
            v-for="deposit in getReportingObjectifs.deposits"
            :key="deposit.id"
          >
            <th class="grey-light sticky">{{ deposit.filiale }}</th>
            <th class="grey-light t-up sticky offset">{{ deposit.name }}</th>
            <td>{{ deposit.totals.pose_objectif_w }}</td>
            <td>{{ deposit.totals.m_objectif_w }}</td>
            <td>{{ deposit.totals.pose_planifie_w }}</td>
            <td>{{ deposit.totals.m_planife_w }}</td>
            <td class="grey">{{ deposit.totals.pose_realise_w }}</td>
            <td class="grey">{{ deposit.totals.m_realise_w }}</td>
            <template v-for="(day, index) in deposit.days">
              <td :key="'d1' + index">
                {{ day.totals_day.pose_objectif }}
              </td>
              <td :key="'d0' + index">{{ day.totals_day.m_objectif }}</td>
              <td :key="'d3' + index">
                {{ day.totals_day.pose_planifie }}
              </td>
              <td :key="'d2' + index">{{ day.totals_day.m_planife }}</td>
              <td class="grey" :key="'d5' + index">
                {{ day.totals_day.pose_realise }}
              </td>
              <td class="grey" :key="'d4' + index">
                {{ day.totals_day.m_realise }}
              </td>
            </template>
          </tr>
          <tr class="tr-2">
            <th colspan="2" rowspan="3" class="grey-light s-14 t-up sticky">
              Total
            </th>
            <th colspan="6" class="grey-light s-14">
              Total global
            </th>
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
          </tr>
          <tr class="tr-2">
            <th colspan="2" class="grey-light">Objectif</th>
            <th colspan="2" class="blue">Planifié</th>
            <th colspan="2" class="yellow">Realisé</th>
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th>Pose</th>
            <th>m²</th>
            <th class="grey">Pose</th>
            <th class="grey">m²</th>
          </tr>
          <tr
            class="tr-2"
            v-if="getReportingObjectifs.totals && getReportingObjectifs.days"
          >
            <td>
              {{
                getReportingObjectifs.totals
                  ? getReportingObjectifs.totals.pose_objectif
                  : 0
              }}
            </td>
            <td>
              {{
                getReportingObjectifs.totals
                  ? getReportingObjectifs.totals.m_objectif
                  : 0
              }}
            </td>
            <td>
              {{
                getReportingObjectifs.totals
                  ? getReportingObjectifs.totals.pose_planifie
                  : 0
              }}
            </td>
            <td>
              {{
                getReportingObjectifs.totals
                  ? getReportingObjectifs.totals.m_planife
                  : 0
              }}
            </td>
            <td class="grey">
              {{
                getReportingObjectifs.totals
                  ? getReportingObjectifs.totals.pose_realise
                  : 0
              }}
            </td>
            <td class="grey">
              {{
                getReportingObjectifs.totals
                  ? getReportingObjectifs.totals.m_realise
                  : 0
              }}
            </td>
            <template v-for="(day, index) in getReportingObjectifs.days">
              <td :key="'dd1' + index">{{ day.pose_objectif }}</td>
              <td :key="'dd0' + index">{{ day.m_objectif }}</td>
              <td :key="'dd3' + index">{{ day.pose_planifie }}</td>
              <td :key="'dd2' + index">{{ day.m_planife }}</td>
              <td class="grey" :key="'dd5' + index">
                {{ day.pose_realise }}
              </td>
              <td class="grey" :key="'dd4' + index">
                {{ day.m_realise }}
              </td>
            </template>
          </tr>
        </table>
      </div>
      <div class="chart-container" v-if="displayCondition">
        <GChart
          :createChart="createChart"
          :data="getReportingObjectifs.chartDataDeposit"
          :options="charDepositOptions"
        />
        <GChart
          :createChart="createChart"
          :data="getReportingObjectifs.chartDataFiliale"
          :options="charFilialeOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GChart } from 'vue-google-charts'
export default {
  components: {
    GChart
  },
  props: {
    selectedWeek: {
      required: true
    }
  },
  data() {
    return {
      charDepositOptions: {
        width: 500,
        height: 400,
        is3D: true,
        title: 'Répartition Réalisé / Dépôt',
        pieSliceText: 'label',
        legend: {
          position: 'labeled',
          textStyle: { color: '#26292c', bold: true, fontSize: 12 }
        },
        titleTextStyle: {
          color: '#26292c',
          fontSize: 20
        },
        colors: [
          '#b0c4de',
          '#b0e0e6',
          '#add8e6',
          '#87cefa',
          '#00bfff',
          '#1e90ff',
          '#6495ed',
          '#4682b4',
          '#4169e1',
          '#0000ff',
          '#0000cd',
          '#00008b',
          '#000080',
          '#191970'
        ],
        chartArea: {
          left: 0,
          height: 250,
          width: 600
        }
      },
      charFilialeOptions: {
        width: 500,
        height: 400,
        is3D: true,
        title: 'Répartition Réalisé / Filiale',
        pieSliceText: 'label',
        legend: {
          position: 'labeled',
          textStyle: { color: '#26292c', bold: true, fontSize: 12 }
        },
        titleTextStyle: {
          color: '#26292c',
          fontSize: 20
        },
        colors: [
          '#b0c4de',
          '#b0e0e6',
          '#add8e6',
          '#87cefa',
          '#00bfff',
          '#1e90ff',
          '#6495ed',
          '#4682b4',
          '#4169e1',
          '#0000ff',
          '#0000cd',
          '#00008b',
          '#000080',
          '#191970'
        ],
        chartArea: {
          left: 0,
          height: 250,
          width: 600
        }
      }
    }
  },
  methods: {
    ...mapActions(['fetchObjectifs', 'fetchVisiblesDeposits']),
    createChart(el, google) {
      if (!el) {
        return
      }
      return new google.visualization.PieChart(el)
    }
  },
  computed: {
    ...mapGetters([
      'getReportingObjectifs',
      'getReportingProcessing',
      'getReportingError'
    ]),
    displayCondition: function() {
      return (
        !this.getReportingProcessing &&
        this.getReportingObjectifs &&
        this.getReportingObjectifs.loaded
      )
    }
  },
  async mounted() {
    await this.fetchVisiblesDeposits()
    if (this.selectedWeek) {
      this.fetchObjectifs(this.selectedWeek)
    }
  },
  watch: {
    selectedWeek: function(week) {
      if (week) {
        this.fetchObjectifs(this.selectedWeek)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.objectifs {
  height: 100%;
  .title {
    font-size: 26px;
  }

  .objectifs-container {
    height: calc(100% - 33px);
    overflow-y: auto;
    overflow-x: hidden;
    .grid-container {
      display: block;
      position: relative;
      overflow: auto;
      margin-right: 2px;
      table {
        &.grid {
          width: 1875px;
          border-collapse: collapse;
          table-layout: fixed;
          box-sizing: border-box;
          empty-cells: show;
          outline: 0;
          tr {
            height: 30px;
            &.tr-1 {
              height: 38px;
            }
            &.tr-2 {
              height: 22px;
            }

            th,
            td {
              position: relative;
              text-align: center;
              border: 1px solid #ced4da;
              outline: 0;
              vertical-align: middle;
              overflow: hidden;
              font-weight: 400;
              text-overflow: ellipsis;
              font-size: 11px;
              white-space: nowrap;
              box-sizing: border-box;
              padding: 0 1px;

              &.blue-white {
                background-color: #2dabe2;
                color: #fff;
              }
              &.blue {
                background-color: #2dabe2;
                color: #fff;
              }
              &.yellow {
                background-color: #ffe436;
              }

              &.grey {
                background-color: #dbdbdb;
              }

              &.grey-light {
                background-color: #ededed;
              }

              &.s-12 {
                font-size: 12px;
              }

              &.s-14 {
                font-size: 14px;
              }
              &.t-up {
                text-transform: uppercase;
              }

              &.sticky {
                position: sticky;
                left: 0;
                z-index: 1;
              }
              &.offset {
                left: 100px;
              }
            }

            th {
              font-weight: 600;
            }

            &:hover {
              background-color: #ededed;
            }
          }
        }
      }
    }
    .chart-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      min-height: 400px;
      margin-top: 30px;
    }
    .error {
      color: #ec2f1c;
    }
  }
}
</style>
